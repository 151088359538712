/* Apply styles to general elements in the custom toolbar */
.custom-toolbar .MuiButton-root,
.custom-toolbar .MuiIconButton-root,
.custom-toolbar .MuiButton-label,
.custom-toolbar .MuiInputBase-root,
.custom-toolbar .MuiSvgIcon-root {
  font-family: 'Cairo' !important;
  font-size: 20px !important;
  color: rgb(1, 44, 1);
}

.ltr-direction {
  direction: ltr;
}


.custom-left-align {
  text-align: left !important;
}

.custom-right-align {
  text-align: right !important;
}

/* Style for the toolbar container */
.custom-toolbar {
  font-family: 'Cairo' !important;
  font-size: 20px !important;
  color: rgb(1, 44, 1);
}

/* Styling for the GridToolbarQuickFilter */
.custom-toolbar-GridToolbarQuickFilter {
  font-family: 'Cairo';
  font-size: 20px;
  margin-right: 25px;
  color: rgb(1, 44, 1);
}

/* Change border-bottom color of the input */
.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root {
  font-family: 'Cairo';
  font-size: 20px;
  color: rgb(1, 44, 1);
}

/* Target the input underline directly */
.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline {
  border-bottom: 2px solid rgb(2, 44, 2); /* Default underline color */
}

/* Change underline color to green when input is focused or active */
.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:before {
  border-bottom: 2px solid rgb(2, 44, 2); /* Underline color before focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:after {
  border-bottom: 2px solid rgb(2, 44, 2); /* Underline color after focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline.Mui-focused:after {
  border-bottom: 2px solid rgb(2, 44, 2); /* Green underline color when focused */
}

/* Handle focus and active states */
.custom-toolbar-GridToolbarQuickFilter input:focus,
.custom-toolbar-GridToolbarQuickFilter input:active {
  outline: none; /* Remove default focus outline */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline {
  border-bottom: 2px solid green; /* Default border color */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:before {
  border-bottom: 2px solid green; /* Border color before focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:after {
  border-bottom: 2px solid green; /* Border color after focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline.Mui-focused:after {
  border-bottom: 2px solid green; /* Border color when focused */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline {
  border-bottom: 2px solid green; /* Default underline color */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:before {
  border-bottom: 2px solid green; /* Border color before focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline:after {
  border-bottom: 2px solid green; /* Border color after focus */
}

.custom-toolbar-GridToolbarQuickFilter .MuiInputBase-root .MuiInput-underline.Mui-focused:after {
  border-bottom: 2px solid green; /* Border color when focused */
}
