/* Center align the select element */
select {
  display: block;
  margin: 0 auto;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-bottom: 10px;
}

/* Center align the title of the select element */
select::before {
  content: "Camera Selection";
  display: block;
  text-align: center;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-bottom: 10px !important;
}

#html5-qrcode-anchor-scan-type-change {
  background-color: #4CAF50 !important;
  color: #eff0f4;
  border-color: #e0e4e875;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 3px !important;
  /* Add border size of 11px */
  padding: 10px !important;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  /* Remove underline from the link */

  cursor: pointer;
  margin: 20px
}

#html5-qrcode-button-camera-permission {
  background-color: #4CAF50 !important;
  color: #eff0f4;
  border-color: #e0e4e875;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 3px !important;
  /* Add border size of 11px */
  padding: 10px !important;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  /* Remove underline from the link */

  cursor: pointer;
  margin: 20px !important;
}

#html5-qrcode-button-camera-start {
  background-color: #4CAF50 !important;
  color: #eff0f4;
  border-color: #e0e4e875;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 3px !important;
  /* Add border size of 11px */
  padding: 10px !important;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  /* Remove underline from the link */
  cursor: pointer;
  margin: 20px !important;
}

#html5-qrcode-button-camera-stop {
  background-color: #4CAF50 !important;
  color: #eff0f4;
  border-color: #e0e4e875;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 3px !important;
  /* Add border size of 11px */
  padding: 10px !important;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  /* Remove underline from the link */
  cursor: pointer;
  margin: 20px !important;
}

#html5-qrcode-button-file-selection {
  background-color: #4CAF50 !important;
  color: #eff0f4;
  border-color: #e0e4e875;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 3px !important;
  /* Add border size of 11px */
  padding: 10px !important;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  /* Remove underline from the link */
  cursor: pointer;
  margin: 20px !important;
}

#html5qr-code-full-region__dashboard_section div {

  font-size: 18px !important;
  font-weight: 900 !important;

}