/* styles.css */
.input-label-endicape {
    font-family: "Tajawal", sans-serif;
    font-size: 16px;
    color: #004d40;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .input-label-endicape:hover,
  .input-label-endicape.Mui-focused {
    color: #004d40; /* Light green color when hovered or focused */
    border-color: #004d40; /* Light green border color when hovered or focused */
  }
  
  /* Ensures the InputLabel's focused border color is applied correctly */
  .MuiInputLabel-root.Mui-focused {
    color: #004d40; /* Light green color when focused */
  }

  
/* styles.css */
.select-endicape {
    font-family: "Tajawal", sans-serif;
  }
  
  .select-endicape .MuiFormHelperText-root {
    font-family: "Tajawal", sans-serif;
    font-size: 14px;
    color: #004d40;
    font-weight: 700;
  }
  
  .select-endicape .MuiFormLabel-root {
    font-family: "Tajawal", sans-serif;
    font-size: 16px;
    color: #004d40;
    border-color: #004d40;
  }
  
  .select-endicape .MuiFormLabel-root:hover {
    color: #004d40; /* Change color when hovered */
    border-color: #004d40; /* Change border color when hovered */
  }
  
  .select-endicape .MuiFormLabel-root.Mui-focused {
    color: #81c784; /* Change color when focused */
    border-color: #81c784; /* Change border color when focused */
  }
  
  .select-endicape .MuiInputBase-input {
    font-family: "Tajawal", sans-serif;
    font-size: 18px;
    color: #004d40;
    font-weight: 700;
  }
  
  .select-endicape label.Mui-focused {
    font-size: 16px;
    color: #004d40;
    font-weight: 700;
  }
  
  .select-endicape .MuiInput-underline:after {
    border-bottom-color: #004d40;
  }
  
  .select-endicape .MuiOutlinedInput-root fieldset {
    font-family: "Tajawal", sans-serif;
    font-size: 16px;
    border-color: #4caf50; /* Default light green border color */
    border-width: 2px;
  }
  
  .select-endicape .MuiOutlinedInput-root:hover fieldset {
    border-color: #004d40; /* Light green color when hovering */
  }
  
  .select-endicape .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #81c784; /* Light green color when focused */
  }
  
  .select-endicape .MuiOutlinedInput-notchedOutline {
    border-color: #81c784; /* Light green color for focused outline */
  }
  
  .select-endicape .MuiSelect-select.Mui-focused {
    border-color: #81c784; /* Light green color for focused select */
  }
  
  .select-endicape .MuiSelect-select:focus {
    border-color: #81c784; /* Light green color for focused select */
  }
  