.responsive-table {
    display: block; /* Default to block for larger screens */
  }
  .mobile-table {
    display: none; /* Hide the table */
  }

  /* For mobile devices */
  @media (max-width: 768px) { /* Adjust the breakpoint as needed */
    .responsive-table {
      display: none; /* Hide the table */
    }
    
    .mobile-table {
      display: block; /* Display mobile-friendly version */
    }
  }