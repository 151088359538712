@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message {
  font-size: 40px;
  /* Adjust font size as needed */
  color: rgb(82, 45, 245);
  text-align: center;
  font-family: 'Amiri', serif;

}