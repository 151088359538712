@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');
/* font-family: 'Amiri', serif;
 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* font-family: 'Roboto Slab', serif; */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');
/* font-family: 'Cairo', sans-serif;
 */

 @import url('https://fonts.googleapis.com/css2?family=Changa:wght@700&display=swap');
/* font-family: 'Changa', sans-serif;
 */


 @import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
 /* font-family: 'Cairo', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Tajawal:wght@400;700;900&display=swap');
/* font-family: 'Tajawal', sans-serif;
*/

@font-face {
    font-family: 'arabicejaza';
    src: url('../../public/arabic-ejaza.ttf') format('truetype');
  }
  