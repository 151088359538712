@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

.header {
  background: linear-gradient(135deg, rgba(0, 105, 92, 0.8), rgba(0, 77, 64, 0.8), rgba(0, 51, 46, 0.8));
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 100%;
  text-align: center;
}

.text-content {
  margin-left: 10px;
}

.stamp {
  width: 70px;
  height: auto;
  opacity: 0.5;
  margin: 0 5px;
}

.header h1,
.header h2 {
  font-family: 'Cairo', serif;
  font-size: 1.5rem;
  margin: 5px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    padding: 15px;
  }

  .header h1,
  .header h2 {
    font-size: 1.2rem;
  }

  .stamp {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .header h1,
  .header h2 {
    font-size: 0.75rem;
  }

  .stamp {
    width: 50px;
  }
}

/* Target the scrollbar width for webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Custom width of the scrollbar */
}

/* Scrollbar track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #ffffff; /* Lighter background for the track */
  border-radius: 10px; /* Optional: round the scrollbar track edges */
}

/* Scrollbar handle (scroll thumb) */
::-webkit-scrollbar-thumb {
  background-color: #004d40; /* Dark teal for the handle */
  border-radius: 10px; /* Smooth, rounded handle */
  border: 2px solid #ffffff; /* Add contrast between handle and track */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition on hover */
}

/* Scrollbar handle hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #00332b; /* Darker shade on hover */
  border-color: #ffffff; /* Maintain contrast on hover */
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin; /* Make the scrollbar thinner in Firefox */
  scrollbar-color: #004d40 #ffffff; /* Handle color (teal) and track color (lighter teal) */
}

/* Scrollbar handle (scroll thumb) for Firefox */
*::-moz-scrollbar-thumb {
  background-color: #004d40; /* Same teal color for Firefox handle */
  border-radius: 10px; /* Rounded handle for consistency */
}

/* Hover effect for Firefox scrollbar handle */
*::-moz-scrollbar-thumb:hover {
  background-color: #00332b; /* Darker on hover for consistency */
}
